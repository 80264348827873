'use client';
import { useAppContext } from '@/app/context';
import { timerAlert } from '@/app/helpers/alerts';
import { setDelivery, setIsAuth, setIsLoading, setSessionData, setStepSideNav, setUserOrderId } from '@/app/redux.actions/items.actions';
import AxiosService from '@/app/services/AxiosServices';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../spinner/Spinner';
import { useStorage } from '../../hooks/useStorage';
import { decryptJWT } from '@/app/helpers/jwtFunctions';

const offices = [
    { value: 612, name: 'Fundadores', city: 'Armenia' },
    { value: 488, name: 'Barrancabermeja', city: 'Barrancabermeja' },
    { value: 205, name: 'Alto Prado', city: 'Barranquilla' },
    { value: 401, name: 'Cabecera', city: 'Bucaramanga' },
    { value: 484, name: 'Bucaramanga Centro', city: 'Bucaramanga' },
    { value: 301, name: 'Cali Centro', city: 'Cali' },
    { value: 302, name: 'Carrera Primera', city: 'Cali' },
    { value: 304, name: 'Santa Mónica', city: 'Cali' },
    { value: 314, name: 'Jardín Plaza', city: 'Cali' },
    { value: 501, name: 'Cartagena Principal', city: 'Cartagena' },
    { value: 489, name: 'Cúcuta Principal', city: 'Cúcuta' },
    { value: 253, name: 'Ibagué Avenida Quinta', city: 'Ibagué' },
    { value: 611, name: 'Manizales', city: 'Manizales' },
    { value: 101, name: 'Medellín Centro', city: 'Medellín' },
    { value: 160, name: 'Laureles', city: 'Medellín' },
    { value: 859, name: 'El Poblado', city: 'Medellín' },
    {
        value: 221,
        name: 'Centro Comercial Fontanar',
        city: 'Municipio De Chia',
    },
    { value: 369, name: 'Pasto', city: 'Pasto' },
    { value: 864, name: 'Pereira Centro', city: 'Pereira' },
    { value: 380, name: 'Popayán', city: 'Popayán' },
    { value: 520, name: 'Rionegro', city: 'Rionegro' },
    { value: 291, name: 'Tunja', city: 'Tunja' },
    { value: 272, name: 'Villavicencio', city: 'Villavicencio' },
];

const officesBogota = [
    { value: 5, name: 'Centro Internacional', city: 'Bogotá' },
    { value: 7, name: 'Calle 73', city: 'Bogotá' },
    { value: 8, name: 'Carrera 15 Calle 94', city: 'Bogotá' },
    { value: 13, name: 'Calle 82 Carrera 11', city: 'Bogotá' },
    { value: 14, name: 'Santa Bárbara', city: 'Bogotá' },
    { value: 17, name: 'El Bosque', city: 'Bogotá' },
    { value: 96, name: 'Centro Comercial Unicentro', city: 'Bogotá' },
    { value: 720, name: 'Gran Estación', city: 'Bogotá' },
    { value: 721, name: 'Centro Comercial Bulevar', city: 'Bogotá' },
    { value: 807, name: 'Avenida El Dorado', city: 'Bogotá' },
    { value: 808, name: 'World Trade Center', city: 'Bogotá' },
    { value: 810, name: 'Cardioinfantil', city: 'Bogotá' },
];

const SecondStep = () => {
    const dispatch = useDispatch();
    const { orderId, delivery, sessionData, isLoading } = useSelector(
        (state) => state.appStore
    );

    const axiosService = new AxiosService();
    const [mainLocation, setMainLocation] = useState('Bogotá'); // Radio buttons

    // Estados para los selectores en Bogotá
    const [bogotaFirstSelect, setBogotaFirstSelect] = useState('');
    const [bogotaSecondSelect, setBogotaSecondSelect] = useState('');

    // Estados para los selectores en Otras ciudades
    const [otrasFirstSelect, setOtrasFirstSelect] = useState('');
    const [otrasSecondSelect, setOtrasSecondSelect] = useState('');

    const uniqueCities = [...new Set(offices.map((office) => office.city))];
    const filteredOffices = offices.filter(
        (office) => office.city === otrasFirstSelect
    );
    const storage =  useStorage();


    // update the state of the delivery useeffect
    useEffect(() => {
        dispatch(
            setDelivery({
                city: mainLocation === 'Bogotá' ? 'Bogotá' : otrasFirstSelect,
                office:
                    mainLocation === 'Bogotá'
                        ? bogotaFirstSelect !== 'Oficina'
                            ? bogotaFirstSelect
                            : bogotaSecondSelect
                        : otrasSecondSelect,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mainLocation,
        bogotaFirstSelect,
        bogotaSecondSelect,
        otrasFirstSelect,
        otrasSecondSelect,
    ]);

    const handleCityChange = (e) => {
        setOtrasFirstSelect(e.target.value);
        setOtrasSecondSelect('');
    };

    const handleOfficeChange = (e) => {
        setBogotaFirstSelect(e.target.value);
        if (mainLocation === 'Bogotá' && e.target.value === 'Oficina') {
            setBogotaSecondSelect(''); // Limpiar el segundo selector de Bogotá
        }
    };

    const handleBogotaOfficeChange = (e) => {
        setBogotaSecondSelect(e.target.value);
    };

    const handleNextStep = async () => {
        if (mainLocation === 'Bogotá') {
            if (!bogotaFirstSelect) {
                await timerAlert( 'Error', 'Por favor selecciona una opción en Bogotá.', 'error' );
                return;
            }
            if (bogotaFirstSelect === 'Oficina' && !bogotaSecondSelect) {
                await timerAlert( 'Error', 'Por favor selecciona una oficina en Bogotá.', 'error' );
                return;
            }
        } else {
            if (!otrasFirstSelect) {
                await timerAlert( 'Error', 'Por favor selecciona una ciudad.', 'error' );
                return;
            }
            if (!otrasSecondSelect) {
                await timerAlert( 'Error', 'Por favor selecciona una oficina.', 'error' );
                return;
            }
        }

        dispatch(setIsLoading(true));

        const token = storage.getLocal('token');
        const isTokenValid = await decryptJWT(token);

        if (!isTokenValid) {
            await timerAlert( 'Error', 'La sesión ha expirado', 'error' );
            dispatch(setIsAuth(false));
            storage.clearLocal();
            removeAuthCookie();
            dispatch(setSessionData({ userId: '', role: '' }));
            setTimeout(() => { window.location.href = '/auth'; }, 2000);
            return;
        } 

        let idOrder = "";

        try {
            const userOrders = await axiosService.getDataById(`/orders/user`, isTokenValid.payload.id);
            
            if (userOrders && userOrders.length > 0) {
                idOrder = userOrders[0].id;
                dispatch(setUserOrderId(idOrder));
    
                const data = {
                    city: delivery.city,
                    office: delivery.office,
                    orderId: idOrder,
                    userId: sessionData.userId,
                    status: 'Pendiente',
                }
    
                try {
                    const deliveryResponse = await axiosService.createData('/deliveries/new-delivery', data);
                    if (deliveryResponse.status === 201) {
                        await timerAlert( 'Éxito', 'Orden creada con éxito, serás redirigido a la plataforma de pagos.', 'success' );
                        const url = deliveryResponse?.data?.urlRedirect;
                        if (url) {
                            window.location.href = url;
                        } else {
                            await timerAlert( 'Error', 'Error al crear la orden.', 'error');
                            dispatch(setIsLoading(false));
                        }
                    }
                } catch (error) {
                    await timerAlert( 'Error', 'Error al crear la orden.', 'error' );
                } finally {
                    dispatch(setIsLoading(false));
                }
    
            } else { 
                await timerAlert(
                    'Error',
                    'No se encontraron órdenes para el usuario.',
                    'error'
                );
                dispatch(setIsLoading(false));
            }
        } catch (error) {
            await timerAlert('Error', 'Error al obtener las órdenes del usuario.', 'error');
            dispatch(setIsLoading(false));
        }

    };

    const goToCart = () => {
        dispatch(setStepSideNav(1));
    };

    return (
        <div className="content">
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <p className="title">¿A dónde llegamos?</p>
                    <p className="descr">
                        Indícanos tu ubicación y selecciona la sede
                        administrativa u oficina más cerca de ti.
                    </p>

                    <div className="radios_location">
                        <p className="radios_title">Ubicación</p>
                        <div className="radio_container">
                            <input
                                type="radio"
                                id="radio1"
                                name="mainLocation"
                                value="Bogotá"
                                checked={mainLocation === 'Bogotá'}
                                onChange={() => {
                                    // Limpiar los selects
                                    setBogotaFirstSelect('');
                                    setBogotaSecondSelect('');
                                    setOtrasFirstSelect('');
                                    setOtrasSecondSelect('');
                                    setMainLocation('Bogotá');
                                }}
                            />
                            <label className="label" htmlFor="radio1">
                                Bogotá
                            </label>

                            <input
                                type="radio"
                                id="radio2"
                                name="mainLocation"
                                value="Otras"
                                checked={mainLocation === 'Otras'}
                                onChange={() => {
                                    // Limpiar los selects
                                    setBogotaFirstSelect('');
                                    setBogotaSecondSelect('');
                                    setOtrasFirstSelect('');
                                    setOtrasSecondSelect('');
                                    setMainLocation('Otras');
                                }}
                            />
                            <label className="label" htmlFor="radio2">
                                Otras ciudades
                            </label>
                        </div>
                    </div>

                    <div className="select_delivery">
                        <p className="select_title">Recoger en</p>
                        {mainLocation === 'Bogotá' ? (
                            <>
                                <select
                                    name="bogotaFirstSelect"
                                    id="bogotaFirstSelect"
                                    className="select"
                                    value={bogotaFirstSelect}
                                    onChange={handleOfficeChange}
                                >
                                    <option value="">
                                        Selecciona una opción
                                    </option>
                                    <option value="Centro de distribución y logística calle 12">
                                        Centro de distribución y logística calle
                                        12
                                    </option>
                                    <option value="Centro de distribución y logística calle 100">
                                        Centro de distribución y logística calle
                                        100
                                    </option>
                                    <option value="Oficina">Oficina</option>
                                </select>

                                {bogotaFirstSelect === 'Oficina' && (
                                    <select
                                        name="bogotaSecondSelect"
                                        id="bogotaSecondSelect"
                                        className="select mt-4"
                                        value={bogotaSecondSelect}
                                        onChange={handleBogotaOfficeChange}
                                    >
                                        <option value="">
                                            Selecciona una oficina
                                        </option>
                                        {officesBogota.map((office, index) => (
                                            <option
                                                key={index}
                                                value={office.name}
                                            >
                                                {office.name}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </>
                        ) : (
                            <>
                                <select
                                    name="otrasFirstSelect"
                                    id="otrasFirstSelect"
                                    className="select"
                                    value={otrasFirstSelect}
                                    onChange={handleCityChange}
                                >
                                    <option value="">
                                        Selecciona una ciudad
                                    </option>
                                    {uniqueCities.map((city, index) => (
                                        <option key={index} value={city}>
                                            {city}
                                        </option>
                                    ))}
                                </select>

                                {otrasFirstSelect && (
                                    <select
                                        name="otrasSecondSelect"
                                        id="otrasSecondSelect"
                                        className="select mt-4"
                                        value={otrasSecondSelect}
                                        onChange={(e) =>
                                            setOtrasSecondSelect(e.target.value)
                                        }
                                    >
                                        <option value="">
                                            Selecciona una oficina
                                        </option>
                                        {filteredOffices.map(
                                            (office, index) => (
                                                <option
                                                    key={index}
                                                    value={office.name}
                                                >
                                                    {office.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                )}
                            </>
                        )}
                    </div>

                    <div className="button_container">
                        <button
                            className="button_go_cart"
                            onClick={handleNextStep}
                        >
                            Finalizar compra
                        </button>
                        <button
                            className="button_go_cart continue"
                            onClick={goToCart}
                        >
                            Volver al carrito
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default SecondStep;
