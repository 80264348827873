import { setIsLoading, setTotal } from "../redux.actions/items.actions";
import AxiosService from "../services/AxiosServices";

const modifyDatabaseCart = async (storeCart, dispatch, stepSideNav) => {

    const axiosService = new AxiosService();
    let userId;

    dispatch(setIsLoading(true));
    const getSessionFromService = await axiosService.getData(
        '/auth/get-session'
    );
    dispatch(setIsLoading(false));

    if (!getSessionFromService?.user?.id) {
        await timerAlert( 'Error', 'No se pudo obtener la sesión', 'error', 2000 );
        return;
    }

    userId = getSessionFromService.user.id;
    const data = {
        userId,
        products: storeCart.map((cartItem) => {
            return {
                id: cartItem.id,
                quantity: cartItem.quantity,
                sizeId: cartItem?.size
                    ? cartItem.sizes.find((el) => el.sizeName === cartItem.size)
                          .id
                    : null,
                gender: cartItem.gender ? cartItem.gender : null, 
                
            };
        })
    };

    dispatch(setIsLoading(true));
    const userOrders = await axiosService.getDataById(
        `/orders/user`,
        userId
    );

    dispatch(setIsLoading(false));


    if (stepSideNav === 1 || stepSideNav === 2) {
        if (userOrders.length === 0) {
            dispatch(setIsLoading(true));
            const resultCreate = await axiosService.createData( '/orders/new-order', data );
            if (resultCreate.data?.order?.total){
                dispatch(setTotal(resultCreate?.data?.order?.total));
            }
                
            dispatch(setIsLoading(false));
            return;
        } 
        const orderId = userOrders[0]?.id;
        dispatch(setIsLoading(true));
        const resultUpdate = await axiosService.updatePatchData( `/orders/edit`, orderId, data );
        dispatch(setIsLoading(false));
        if (resultUpdate.data?.order?.total)
            dispatch(setTotal(resultUpdate.data?.order?.total));
    }
};

export default modifyDatabaseCart;