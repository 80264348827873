'use client';
import { useAppContext } from '@/app/context';
import Image from 'next/image';
import FirstStep from '../side-nav-first-step/FirstStep';
import SecondStep from '../side-nav-second-step/SecondStep';
import ThirthStep from '../side-nav-succefull/ThirthStep';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCart,
    setIsAuth,
    setOpenSideNav,
    setStepSideNav,
    setUserOrderId,
    setUserSuccessOrder,
} from '@/app/redux.actions/items.actions';
import getQuantity from '@/app/helpers/getQuantityCartItems';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import updateCartFromDB from '@/app/helpers/updateCartFromDB';
import { useStorage } from '@/app/hooks/useStorage';
import { decryptJWT } from '@/app/helpers/jwtFunctions';
import { removeAuthCookie } from '@/app/helpers/authCookie';
import AxiosService from '@/app/services/AxiosServices';
import { timerAlert } from '@/app/helpers/alerts';
import getVoucherInfo from '@/app/helpers/getVoucherInfo';

const SideNav = () => {
    const dispatch = useDispatch();
    const { openSideNav, stepSideNav, cart, isLoading } = useSelector(
        (state) => state.appStore
    );

    const [isMounted, setIsMounted] = useState(false);
    const [localIsLading, setLocalIsLoading] = useState(false);

    const storage = useStorage();
    const searchParams = useSearchParams();
    const paymentId = searchParams.get('payment');
    const axiosService = new AxiosService();
    const router = useRouter();

    const handleInvalidToken = async () => {
        await timerAlert('Error', 'La sesión ha expirado', 'error');
        dispatch(setIsAuth(false));
        storage.clearLocal();
        removeAuthCookie();
        setTimeout(() => {
            window.location.href = '/auth';
        }, 2000);
    };

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        const fetchCart = async () => {
            const token = storage.getLocal('token');
            if (!token) return;

            const isTokenValid = await decryptJWT(token);
            if (!isTokenValid) {
                await handleInvalidToken();
                return;
            }

            const data = { userId: isTokenValid.payload.id };
            try {
                setLocalIsLoading(true);
                const userOrders = await getVoucherInfo(data);

                if (!userOrders?.data?.orders?.length) {
                    dispatch(setStepSideNav(0));
                    dispatch(setOpenSideNav(false));
                    return;
                }

                const userOrdersData = userOrders.data.orders;
                const userOrderId = userOrdersData[0].id;
                dispatch(setUserOrderId(userOrderId));

                const new_cart = await updateCartFromDB(userOrdersData);
                if (new_cart) {


                    dispatch(setUserSuccessOrder(new_cart, userOrders?.data?.transactionDetails, userOrders?.data?.buyer, userOrders?.data?.message));
                }
            } catch (error) {
                console.error('Error fetching user orders:', error);
                await timerAlert('Error', 'Error al obtener la orden del usuario', 'error');
                
            } finally {
                setLocalIsLoading(false);
                router.push('/');
            }
        };

        if (paymentId) {
            fetchCart();
            dispatch(setStepSideNav(3));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentId]);

    return (
        <div className={`modal_container ${openSideNav ? 'open' : ''}`}>
            <div className="modal_cart">
                <div className="cart_container">
                    {isMounted && stepSideNav !== 3 ? (
                        <>
                            {!isLoading ? (
                                <div className="cart_header">
                                    <Image
                                        className="close_icon"
                                        src="/icons/close.svg"
                                        alt="close"
                                        width={25}
                                        height={25}
                                        onClick={() => {
                                            dispatch(setOpenSideNav(false));
                                            dispatch(setStepSideNav(0));
                                        }}
                                    />

                                    <div className="cart_icon_container">
                                        <Image
                                            className="cart_icon"
                                            src="/icons/cart.svg"
                                            alt="cart"
                                            width={38}
                                            height={38}
                                        />
                                        <p className="cart_counter">
                                            {getQuantity(cart)}
                                        </p>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ) : null}

                    {isMounted && stepSideNav === 1 ? (
                        <FirstStep />
                    ) : isMounted && stepSideNav === 2 ? (
                        <SecondStep />
                    ) : isMounted && stepSideNav === 3 ? (
                        <ThirthStep
                            localIsLading={localIsLading}
                            setLocalIsLoading={setLocalIsLoading}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SideNav;
