const priceFormat = (price) => {

    if (!price) {
        return '';
    }
    // convierte el precio a string
    let strPrice = price.toString();

    // elimina los puntos, comas y signos de pesos
    const cleanPrice = strPrice.replace(/[$.]/g, '');
    // convierte el string a entero
    const priceInt = parseInt(cleanPrice);
    // formatea el precio con separador de miles
    return priceInt.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
    });
};

export default priceFormat;