import { useAppContext } from '@/app/context';
import priceFormat from '@/app/helpers/priceFormat';
import { setOpenSideNav, setStepSideNav } from '@/app/redux.actions/items.actions';
import Image from 'next/image';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../spinner/Spinner';
import { useRouter } from 'next/navigation';

const ThirthStep = ({
    localIsLading,
    setLocalIsLoading,
}) => {

        const dispatch = useDispatch();
        const router = useRouter();
        const { successPaidOrder, total, delivery } = useSelector((state) => state.appStore);
 
    const closeStep = () => {
        dispatch(setOpenSideNav(false));
        dispatch(setStepSideNav(0));

        //remover los query params
        router.push('/');
        

    };

    return <div className="thirth_step">
        <div className='main_thirth'>
            {
                localIsLading ? <Spinner /> : <>
                <div className='left'>
                    <div className="header_left">
                    <Image
                                    className="close_icon"
                                    src="/icons/close.svg"
                                    alt="close"
                                    width={25}
                                    height={25}
                                    onClick={() => {
                                        closeStep();
                                    }}
                                />
    
                                <p>Resumen de la compra</p>
                    </div>
    
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">Producto</th>
                            <th scope="col">Características</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                successPaidOrder?.order && successPaidOrder?.order.map((item, index) => (
                                    <tr key={index}>
                                        <td scope="row" data-label="Producto">
                                            <Image
                                                src={`${process.env.NEXT_PUBLIC_API_URL}/products/images/${item?.images[0]}`}
                                                alt={item.name}
                                                width={150}
                                                height={150}
                                            />
                                        </td>
                                        <td data-label="Características">
                                            {item.size ? (
                                                <>
                                                    <strong>Talla:</strong> {item.size} <br />
                                                </>
                                            ) : 'N/A'}

                                            {item.gender ? (
                                                <>
                                                    <strong>Género:</strong> {item.gender === 'M' ? 'Hombre' : 'Mujer'}
                                                </>
                                            ) : null}

                                        </td>
                                        <td data-label="Cantidad">{item.quantity}</td>
                                        <td data-label="Precio">{priceFormat(item.price * item.quantity)}</td>
                                    </tr>
                                ))
                            }
                            
                        </tbody>
                    </table>
    
                    <div className="total">
                        <p className='text'>Total</p>
                        <p className='quantity'>{priceFormat(successPaidOrder?.transactionDetails?.TransValue)}</p>
                    </div>
                </div>
                <div className="right">
                    <p className='title'>Detalles de la transacción</p>
                    <div className="metod">
                        <p>Método de pago</p>
                        <p>{successPaidOrder?.transactionDetails?.PaymentSystem}</p>
                    </div>
                    <div className="ref">
                        <p>Nro. de referencia</p>
                        <p>{successPaidOrder?.transactionDetails?.TicketId}</p>
                    </div>
                    <div className="authorization">
                        <p>Nro. de autorización</p>
                        <p>{successPaidOrder?.transactionDetails?.TrazabilityCode ? successPaidOrder?.transactionDetails?.TrazabilityCode : 'No registrado'}</p>
                    </div>
                    <div className="value">
                        <p>Valor de factura</p>
                        <p>{priceFormat(successPaidOrder?.transactionDetails?.TransValue)}</p>
                    </div>
                    <div className="value">
                        <p>Ciudad</p>
                        <p>{successPaidOrder?.transactionDetails?.DeliveryCity}</p>
                    </div>
                    <div className="value">
                        <p>Recoger en</p>
                        <p>{successPaidOrder?.transactionDetails?.DeliveryOffice}</p>
                    </div>
    
                    {/* <button className='download_btn'>
                        Descargar ahora
                    </button> */}
                </div>
                </>
            }
        </div>
    </div>;
};

export default ThirthStep;
