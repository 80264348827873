import { timerAlert } from '@/app/helpers/alerts';
import { removeAuthCookie } from '@/app/helpers/authCookie';
import { decryptJWT } from '@/app/helpers/jwtFunctions';
import priceFormat from '@/app/helpers/priceFormat';
import { useStorage } from '@/app/hooks/useStorage';
import {
    removeFromCart,
    setIsAuth,
    setOpenSideNav,
    setStepSideNav,
} from '@/app/redux.actions/items.actions';
import AxiosService from '@/app/services/AxiosServices';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../spinner/Spinner';

const FirstStep = () => {
    const dispatch = useDispatch();
    const {
        cart: cartElements,
        total,
        stepSideNav,
        isLoading,
    } = useSelector((state) => state.appStore);
    const router = useRouter();
    const storage = useStorage();

    useEffect(() => {
        // llamar a la api para obtener los items del carrito
        const getCartItems = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }

            const tokenDecr = await decryptJWT(token);
            if (!tokenDecr) {
                await timerAlert(
                    'Error',
                    'La sesión ha expirado',
                    'error'
                );

                dispatch(setIsAuth(false));
                storage.clearLocal();
                removeAuthCookie();
                setTimeout(() => {
                    window.location.href = '/auth';
                }, 2000);
                return;
            }

            const userId = tokenDecr?.payload?.id;
            if (!userId) {
                return;
            }
        };

        if (stepSideNav === 1) {
            getCartItems();
        }
    });

    const gotoHome = () => {
        dispatch(setStepSideNav(0));
        dispatch(setOpenSideNav(false));
        router.push('/');
    };

    const setSecondStep = () => {
        dispatch(setStepSideNav(2));
    };

    const handleDeleteItem = (item) => {
        dispatch(removeFromCart(item.id, item.size, item.gender));

        // si no hay elementos en el carrito pasar al paso 1 y cerrar el sidenav
        if (cartElements.length === 1) {
            dispatch(setStepSideNav(1));
            dispatch(setOpenSideNav(false));
        }
    };

    return (
        <div className="content">
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <p className="title second">Tu carrito de compras</p>
                    {/* <p className="descr">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        diam nonumy eirmod tempor invidunt ut labore et dolore magna.
                    </p> */}

                    <div className="card_items_container">
                        {cartElements.map((item, index) => {


                            return (
                                <div className="card_item" key={index}>
                                    <div className="card_item_img">
                                        <Image
                                            src={`${process.env.NEXT_PUBLIC_API_URL}/products/images/${item?.images[0]}`}
                                            alt={item?.name || 'product'}
                                            width={1000}
                                            height={1000}
                                        />
                                    </div>
                                    <div className="card_item_info">
                                        <p className="card_item_title">
                                            {item?.name}
                                        </p>
                                        <div className="minibox">
                                            {item?.size ? (
                                                <p className="card_item_talla">
                                                    <strong>Talla:</strong> {item?.size}
                                                </p>
                                            ) : null}

                                            {item?.gender ? (
                                                <p className="card_item_talla">
                                                    <strong>Género:</strong> {item.gender === 'M' ? 'Hombre' : 'Mujer'}
                                                </p>
                                            ) : null}

                                            <p className="card_item_cantidad">
                                             <strong>Cantidad:</strong> {item?.quantity}
                                            </p>
                                        </div>

                                        <p className="card_item_precio">
                                            {priceFormat(
                                                item.price * item.quantity
                                            )}
                                        </p>
                                    </div>
                                    <div className="card_item_delete">
                                        <Image
                                            src="/icons/close.svg"
                                            alt="close"
                                            width={25}
                                            height={25}
                                            onClick={() =>
                                                handleDeleteItem(item)
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="button_container">
                        <div className="total_container">
                            <p className="total_title">Total</p>
                            <p className="total_price">{priceFormat(total)}</p>
                        </div>
                        <button
                            className="button_go_cart"
                            onClick={setSecondStep}
                        >
                            Continuar
                        </button>
                        <button
                            className="button_go_cart continue"
                            onClick={gotoHome}
                        >
                            Sigue comprando
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default FirstStep;
